import React from "react";
import styles from "./Error.module.sass";

const Error = ({description}) => {
  return (
    <div className={styles.errorWrapper}>
      <div className={styles.errorWrapperInner}>
        <div>
          <img className={styles.errorImage} src={'/icon/empty_state.svg'} width={'296'} height={'160'}
               alt="Fehler"/>
        </div>
        <div>
          <span className={styles.errorDescription}>{description}</span>
        </div>
      </div>
    </div>
  );
};

export default Error;
