import React from "react";
import cn from "classnames";
import styles from "./Properties.module.sass";
import ItemPreview from "../../../components/ItemPreview";
import Attribute from "../../../components/Attribute";
import {Link} from "react-router-dom";

const Properties = ({className, plant}) => {

  let attributeOptions = {
    family: {title: 'Familie', imagePath: '/icon/family.png'},
    nutritionalNeeds: {title: 'Nährstoffbedarf', imagePath: '/icon/nutritional_needs.png'},
    earlySeed: {
      valueKey1: 'earlySeedStartTime',
      valueKey2: 'earlySeedEndTime',
      title: 'Vorsaat',
      imagePath: '/icon/seed_time_early.png'
    },
    normalSeed: {
      valueKey1: 'normalSeedStartTime',
      valueKey2: 'normalSeedEndTime',
      title: 'Direktsaat',
      imagePath: '/icon/seed_type_normal.png'
    },
    harvest: {
      valueKey1: 'harvestStartTime',
      valueKey2: 'harvestEndTime',
      title: 'Ernte',
      imagePath: '/icon/harvest_time.png'
    },
    periodOfTime: {
      valueKey1: 'periodOfTimeLower',
      valueKey2: 'periodOfTimeUpper',
      title: 'Aussaat bis Ernte',
      imagePath: '/icon/period_of_time.png',
      unit: 'Wochen'
    },
    germinationTime: {
      valueKey1: 'germinationTimeLower',
      valueKey2: 'germinationTimeUpper',
      title: 'Keimdauer',
      imagePath: '/icon/germination_time.png',
      unit: 'Tage'
    },
    temperature: {
      valueKey1: 'temperatureLower',
      valueKey2: 'temperatureUpper',
      title: 'Keimtemperatur',
      imagePath: '/icon/temperature.png',
      unit: '°C'
    },
    seedDepth: {
      valueKey1: 'seedDepthLower',
      valueKey2: 'seedDepthUpper',
      title: 'Saattiefe',
      imagePath: '/icon/seed_depth.png',
      unit: 'cm'
    },
    plantSpacing: {
      valueKey1: 'plantSpacingLower',
      valueKey2: 'plantSpacingUpper',
      title: 'Pflanzabstand',
      imagePath: '/icon/plant_spacing.png',
      unit: 'cm'
    },
    location: {title: 'Standort', imagePath: '/icon/location.png'},
  };

  function hasAnyAttributeValue(item) {
    return Object.keys(attributeOptions).filter((x) => hasAttributeValue(item, x)).length !== 0;
  }

  function getAttributeValue(item, key) {
    let unit = attributeOptions[key]['unit'] ? " " + attributeOptions[key]['unit'] : "";
    if ('valueKey1' in attributeOptions[key] && item[attributeOptions[key]['valueKey1']]
      && 'valueKey2' in attributeOptions[key] && item[attributeOptions[key]['valueKey2']]) {
      return item[attributeOptions[key]['valueKey1']] + " - " + item[attributeOptions[key]['valueKey2']] + unit;
    } else if ('valueKey1' in attributeOptions[key] && item[attributeOptions[key]['valueKey1']]) {
      return "ab " + item[attributeOptions[key]['valueKey1']] + unit;
    } else if ('valueKey2' in attributeOptions[key] && item[attributeOptions[key]['valueKey2']]) {
      return "bis " + item[attributeOptions[key]['valueKey2']] + unit;
    } else {
      return item[key] + unit;
    }
  }

  function hasAttributeValue(item, key) {
    return !!('valueKey1' in attributeOptions[key] ?
      item[attributeOptions[key]['valueKey1']] || item[attributeOptions[key]['valueKey2']] : item[key]);
  }

  return (
    <div className={className}>
      <div className={styles.details}>
        <div className={styles.title}>
          <h1 className={"h3"}>{plant.name}</h1>
          <span>{plant.otherNames}</span>
        </div>
        {plant.plantCollection && <ItemPreview className={styles.plantCollectionPreview} item={plant.plantCollection}/>}
        <div className={styles.description}>
          <div
            className={styles.allowLineBreaks}>{plant.description ? plant.description : "Für diese Pflanze steht keine Beschreibung zur Verfügung. Bearbeite die Pflanze, um eine Beschreibung hinzuzufügen."}</div>
        </div>
        {plant.collectionMembers && <div>
          <h1 className={"h5"}>Pflanzen in der Gruppe</h1>
          <div className={styles.description}>Bei diesem Eintrag handelt es sich um eine Gruppe. Folgende Pflanzen
            sind enthalten.
          </div>
          {plant.collectionMembers.map((x, index) => (
            <ItemPreview className={styles.itemPreviewList} item={x} key={index}/>
          ))}
        </div>}
        {hasAnyAttributeValue(plant) && <div>
          {Object.keys(attributeOptions).filter((x) => hasAttributeValue(plant, x)).map((x) => (
            <Attribute className={styles.attributes} meta={attributeOptions[x]} value={getAttributeValue(plant, x)}
                       key={x}/>
          ))}
        </div>}
        {/*todo*/}
        {false && <Link
          className={cn("button-stroke button-small", styles.button)}
          to={'/plant/' + plant.uuid + '/edit'}
        >
          <span>Bearbeiten</span>
        </Link>}
      </div>
    </div>
  );
};

export default Properties;
