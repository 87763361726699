import React from "react";
import cn from "classnames";
import {Link} from "react-router-dom";
import styles from "./Item.module.sass";

const Item = ({item}) => {

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <Link to={"/plant/" + item.uuid} key={item.uuid}>
          <div className={styles.preview}>
            <img src={item.imagePath + "?gaat=" + localStorage.getItem("gaat")} alt="Collection"/>
          </div>
          <div className={styles.subtitle}>{item.name}</div>
          {item.collection &&
            <div className={styles.line}>
              <div className={cn("status-stroke-black", styles.counter)}>
                Gruppe
              </div>
            </div>}
        </Link>
      </div>
    </div>
  );
};

export default Item;
