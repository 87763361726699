import React from 'react';
import { createRoot } from 'react-dom/client';
import {BrowserRouter as Router} from "react-router-dom";
import App from './App';
import packageJson from '../package.json';
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";

//SENTRY
Sentry.init({
  dsn: "https://f0a9d4cb09b1498fb50cc0a556deb9e8@o185360.ingest.sentry.io/5935065",
  integrations: [new BrowserTracing()],
  environment: process.env.NODE_ENV,
  tracesSampleRate: 1.0,
  release: packageJson.version,
});


const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Router>
      <App/>
    </Router>
  </React.StrictMode>
);
