import React, {useEffect, useRef, useState} from "react";
import cn from "classnames";
import styles from "./BedDetail.module.sass";
import axios from "axios";
import FullScreenLoader from "../../components/FullScreenLoader";
import Modal from "../../components/Modal";
import TextInput from "../../components/TextInput";
import ItemPreview from "../../components/ItemPreview";
import Checkbox from "../../components/Checkbox";
import {useHistory} from "react-router-dom";

const navLinks = ["Hinweise", "Empfehlungen"];

const BedDetail = (props) => {

  let history = useHistory();

  let [activeIndex, setActiveIndex] = useState(0);
  let [advices, setAdvices] = useState({GENERIC: [], RECOMMENDATION: []});

  let [visibleNameChangeModal, setVisibleNameChangeModal] = useState(false);
  let bedNameRef = useRef();

  let [visibleAddModal, setVisibleAddModal] = useState(false);
  let [plantsToAdd, setPlantsToAdd] = useState([]);

  let [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  let [plantsToDelete, setPlantsToDelete] = useState([]);

  let [allPlants, setAllPlants] = useState([]);

  let [loading, setLoading] = useState(true);
  let [bed, setBed] = useState({});
  let uuid = props.match.params.id;

  useEffect(() => {
    setLoading(() => true);
    axios.get(`/bed/${uuid}`)
      .then(function (response) {
        setBed(() => response.data);
        setLoading(() => false);
      });
  }, [uuid]);

  useEffect(() => {
    let generic = bed.advises ? bed.advises.filter(x => x.type !== 'RECOMMENDATION') : [];
    let recommendation = bed.advises ? bed.advises.filter(x => x.type === 'RECOMMENDATION') : [];
    setAdvices(() => {
      return {
        GENERIC: generic,
        RECOMMENDATION: recommendation
      };
    })
    setActiveIndex(() => generic.length === 0 && recommendation.length > 0 ? 1 : 0);
  }, [bed]);

  useEffect(() => {
    if (allPlants.length !== 0 || !visibleAddModal) {
      return;
    }
    setLoading(() => true);
    axios.get('/plants?plantOnly=true')
      .then(function (response) {
        setAllPlants(() => response.data);
        setLoading(() => false);
      }).catch(function (error) {
      console.log(error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleAddModal]);

  function addOrDeletePlantFromList(e, l) {
    let element = e.target.parentNode.querySelector('input[name="uuid"]').value;
    let position = l.indexOf(element);
    if (e.target.checked) {
      return [...l, element];
    }
    if (position !== -1) {
      l.splice(position, 1);
    }
    return [...l];
  }

  const addPlants = () => {
    if (plantsToAdd.length === 0) {
      return;
    }

    let addedValues = plantsToAdd.map((x) => {
      return {uuid: x}
    });
    let newValues = bed.plantedPlants ? [...bed.plantedPlants, ...addedValues] : addedValues;
    updateBed({plantedPlants: newValues});
  }

  const deletePlants = () => {
    if (plantsToDelete.length === 0) {
      return;
    }

    updateBed({plantedPlants: bed.plantedPlants.filter((x) => !plantsToDelete.includes(x.uuid))})
  }

  const updateBed = (updatedValues) => {
    setLoading(() => true);
    axios.put(
      `/bed/${uuid}`,
      buildBedPayload(updatedValues))
      .then(function () {
        window.location.reload();
      });
  };

  const buildBedPayload = (updatedValues) => {
    let payload = {
      name: bed.name,
      plantedPlants: bed.plantedPlants,
      ...updatedValues
    }
    if (payload.plantedPlants) {
      payload.plantedPlants = [...new Set(payload.plantedPlants.map(x => x.uuid))];
      payload.plantedPlants = payload.plantedPlants.map(x => {
        return {uuid: x}
      });
    }
    return payload;
  };

  const deleteBed = () => {
    setLoading(() => true);
    axios.delete(`/bed/${uuid}`)
      .then(function () {
        history.push(`/beds`);
      });
  };


  if (loading) {
    return <FullScreenLoader/>;
  }

  return (
    <div className={styles.page}>
      <div className={cn("section-pt80", styles.body)}>
        <div className={cn("container", styles.container)}>
          <div className={styles.top}>
            <h1 className={cn("h2", styles.title)}>{bed.name}</h1>
            <button
              className={cn(
                "button-stroke button-small mobile-hide",
                styles.button
              )}
              onClick={() => setVisibleNameChangeModal(() => true)}
            >
              Bearbeiten
            </button>
          </div>
          <div className={styles.row}>
            <div className={styles.wrapper}>
              <div className={styles.nav}>
                {navLinks.map((x, index) => (
                  <button
                    className={cn(styles.link, {
                      [styles.active]: index === activeIndex,
                    })}
                    onClick={() => setActiveIndex(index)}
                    key={index}
                  >
                    {x}
                  </button>
                ))}
              </div>
              <div className={styles.list}>
                {(activeIndex === 0 ? advices['GENERIC'] : advices['RECOMMENDATION']).map((x, index) => (
                  <div className={styles.item} key={index}>
                    <div>
                      <div className={cn(styles.indicator,
                        {[styles.indicatorError]: x.type === 'BAD_NEIGHBOUR'},
                        {[styles.indicatorWarn]: x.type === 'INFORMATION'},
                        {[styles.indicatorSuccess]: ['GOOD_NEIGHBOUR', 'RECOMMENDATION'].includes(x.type)})}
                      >
                      </div>
                    </div>
                    <div className={styles.details}>
                      <div className={styles.detailTitle}>{x.title}</div>
                      {x.type === 'RECOMMENDATION' && !x.affectedPlants[0].collection && <button
                        className={cn("button-stroke", "button-small", styles.detailAdd)}
                        onClick={() => {
                          updateBed({plantedPlants: [...bed.plantedPlants, ...[{uuid: x.affectedPlants[0].uuid}]]});
                        }}>
                        Zum Beet hinzufügen
                      </button>}
                      {x.description && <div className={styles.detailDescription}>{x.description}</div>}
                      <div className={styles.detailPlants}>
                        {x.affectedPlants && x.affectedPlants.map((p, pIndex) =>
                          <div key={pIndex}>
                            <ItemPreview className={styles.itemPreviewItem} item={p}/>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                {activeIndex === 0 && advices['GENERIC'].length === 0 &&
                  <div>Keine Hinweise für das Beet vorhanden</div>}
                {activeIndex === 1 && advices['RECOMMENDATION'].length === 0 &&
                  <div>Keine Pflanzenempfehlungen für das Beet vorhanden</div>}
              </div>
            </div>
            <button
              className={cn(
                "button-stroke button-small mobile-show",
                styles.button
              )}
              onClick={() => setVisibleNameChangeModal(() => true)}
            >
              Bearbeiten
            </button>
            <div className={styles.plantedPlants}>
              <div className={styles.info}>Pflanzen im Beet</div>
              <div className={styles.group}>
                {bed.plantedPlants && bed.plantedPlants.map((x, index) => (
                  <div key={index}>
                    <ItemPreview className={styles.itemPreviewItem} item={x}/>
                  </div>
                ))}
                {(!bed.plantedPlants || bed.plantedPlants.length === 0) &&
                  <div>Aktuell befinden sich keine Pflanzen im Beet</div>}
              </div>
              <div className={styles.btns}>
                <button
                  className={cn("button-small", styles.button)}
                  onClick={() => setVisibleAddModal(() => true)}>
                  Hinzufügen
                </button>
                {bed.plantedPlants && <button
                  className={cn("button-pink", "button-small", styles.button)}
                  onClick={() => setVisibleDeleteModal(() => true)}>
                  Löschen
                </button>}
              </div>
              <Modal
                visible={visibleAddModal}
                onClose={() => {
                  setPlantsToAdd(() => []);
                  setVisibleAddModal(false);
                }}
              >
                <div>
                  <h1 className={cn("h4", styles.modalTop)}>Zum Beet hinzufügen</h1>
                  <div className={styles.itemPreviewList}>
                    {allPlants.map((x, index) => (
                      <div className={styles.itemPreviewItem}>
                        <Checkbox
                          className={styles.checkbox}
                          content={<ItemPreview item={x} link={false}/>}
                          onChange={(e) => setPlantsToAdd(l => addOrDeletePlantFromList(e, l))}
                          key={index}
                        />
                      </div>
                    ))}
                  </div>
                  <div className={styles.modalFooter}>
                    <button
                      className={cn("button-small", styles.modalButton)}
                      onClick={() => addPlants()}
                    >
                      <span>{plantsToAdd.length}</span>&nbsp;Pflanzen hinzufügen
                    </button>
                  </div>
                </div>
              </Modal>
              <Modal
                visible={visibleDeleteModal}
                onClose={() => {
                  setPlantsToDelete(() => []);
                  setVisibleDeleteModal(false);
                }}
              >
                <div>
                  <h1 className={cn("h4", styles.modalTop)}>Aus Beet löschen</h1>
                  <div className={styles.itemPreviewList}>
                    {bed.plantedPlants && bed.plantedPlants.map((x, index) => (
                      <div className={styles.itemPreviewItem}>
                        <Checkbox
                          className={styles.checkbox}
                          content={<ItemPreview item={x} link={false}/>}
                          onChange={(e) => setPlantsToDelete(l => addOrDeletePlantFromList(e, l))}
                          key={index}
                        />
                      </div>
                    ))}
                  </div>
                  <div className={styles.modalFooter}>
                    <button
                      className={cn("button-small button-pink", styles.modalButton)}
                      onClick={() => deletePlants()}
                    >
                      <span>{plantsToDelete.length}</span>&nbsp;Pflanzen löschen
                    </button>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={visibleNameChangeModal}
        onClose={() => setVisibleNameChangeModal(false)}
      >
        <div>
          <div className={styles.modalTop}>
            <h1 className={cn("h4", styles.modalTop)}>Beet bearbeiten</h1>
          </div>
          <div>
            <TextInput
              name="name"
              type="text"
              placeholder="Name"
              defaultValue={bed.name}
              innerRef={bedNameRef}
            />
          </div>
          <div className={styles.modalFooter}>
            <button
              className={cn("button-small", styles.modalButton)}
              onClick={() => {
                let name = bedNameRef.current.value;
                if (!name || name.trim().length === 0) {
                  return;
                }
                updateBed({name: name});
              }}
            >
              Name ändern
            </button>
            <div style={{margin: '26px'}}/>
            <button
              className={cn("button-pink", "button-small", styles.modalButton)}
              onClick={() => deleteBed()}
            >
              Beet löschen
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BedDetail;
