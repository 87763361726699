import React, {useRef, useState} from "react";
import styles from "./Neighbours.module.sass";
import Icon from "../../../components/Icon";
import ItemPreview from "../../../components/ItemPreview";
import Fuse from "fuse.js";

const Neighbours = ({className, plant, neighbours, searchText}) => {

  let wrapper = getWrapper(neighbours);

  let [allNeighbours] = useState(wrapper);
  let [searchNeighbours, setSearchNeighbours] = useState(wrapper);
  let search = useRef("");

  let timer = useRef(null);
  let fuse = useRef(new Fuse(allNeighbours, {
    shouldSort: true,
    threshold: 0.2,
    findAllMatches: true,
    keys: [
      "neighbour.name",
      "neighbour.otherNames"
    ]
  }));

  function getWrapper(items) {
    return items.map(x => {
      return {
        inherited: x.inherited,
        description: x.description,
        neighbour: determineNeighbour(plant, x)
      };
    });
  }

  function determineNeighbour(item, neighbourDeclaration) {
    if (neighbourDeclaration.origin.uuid === item.uuid) {
      return neighbourDeclaration.destination;
    } else {
      return neighbourDeclaration.origin;
    }
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      searchPlant();
    }
  }

  const handleChange = (e) => {
    clearTimeout(timer.current);
    search.current = e.target.value;
    timer.current = setTimeout(searchPlant, 600);
  }

  const searchPlant = () => {
    if (search.current.trim().length === 0) {
      setSearchNeighbours(allNeighbours);
    } else {
      let searchedNeighbours = fuse.current.search(search.current).map(neighbour => neighbour.item);
      setSearchNeighbours(searchedNeighbours);
    }
  };

  return (
    <div className={className}>
      <div className={styles.search}>
        <input
          className={styles.input}
          type="text"
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          name="search"
          placeholder={searchText}
        />
        <button className={styles.result}>
          <Icon name="search" size="16"/>
        </button>
      </div>
      <div>
        {searchNeighbours.map((x, index) => (
          <ItemPreview className={styles.itemPreviewList} item={x.neighbour}
                       subtitle={x.inherited ? 'Von Gruppe geerbt' : ''} description={x.description} key={index}/>
        ))}
      </div>
    </div>
  );
};

export default Neighbours;
