import v8n from "v8n";

class ValidationUtil {

  static NOT_BLANK = 'notBlank';

  static validate = (validationRules, dto) => {
    let errors = [];
    for (const [key, value] of Object.entries(validationRules)) {
      for (const validation of value.validations) {
        switch (validation.type) {
          case ValidationUtil.NOT_BLANK:
            ValidationUtil.appendError(errors, ValidationUtil.validateNotBlank(dto[key], value.displayName));
            break;
          default:
            throw new Error("No validation type set!");
        }
      }
    }
    return errors;
  };

  static appendError = (errors, value) => {
    if (value) {
      errors.push(value);
    }
  }

  static validateNotBlank = (attribute, displayName) => {
    if (v8n()
      .string()
      .empty()
      .test(attribute)) {
      return `${displayName} darf nicht leer sein!`;
    }
    return undefined;
  };
}

export default ValidationUtil;
