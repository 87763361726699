import React, {useEffect, useRef, useState} from "react";
import cn from "classnames";
import styles from "./BedSearch.module.sass";
import FullScreenLoader from "../../components/FullScreenLoader";
import axios from "axios";
import {Link} from "react-router-dom";
import TextInput from "../../components/TextInput";
import Modal from "../../components/Modal";
import {useHistory} from "react-router";

const BedSearch = () => {
  let [loading, setLoading] = useState(true);
  let history = useHistory();

  let [beds, setBeds] = useState([]);

  let [visibleCreateModal, setVisibleCreateModal] = useState(false);
  let bedNameRef = useRef();

  useEffect(() => {
    axios.get('/beds')
      .then(function (response) {
        setBeds(() => response.data);
        setLoading(() => false);
      }).catch(function (error) {
      console.log(error);
    });
  }, []);

  const createBed = (name) => {
    setLoading(() => true);
    axios.post(
      `/beds`,
      {name: name})
      .then(function (response) {
        history.push(`/bed/${response.data.uuid}`);
      });
  };

  if (loading) {
    return <FullScreenLoader/>;
  }

  return (
    <div className={"section-pt80"}>
      <div className={"container"}>
        <div className={styles.top}>
          <div className={styles.actions}>
            <h1 className={"h3"}>Meine Beete</h1>
            <button
              className={cn("button-small")}
              onClick={() => setVisibleCreateModal(() => true)}
            >
              Neues Beet erstellen
            </button>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.wrapper}>
            <div className={styles.list}>
              {beds.map((x, index) =>
                <div className={styles.listItemContainer}>
                  <Link to={'/bed/' + x.uuid}>
                    <div className={styles.listItem} key={index}>
                      <div className={styles.listItemIcon}>
                        <img src={'/icon/flower.svg'} alt="Beet"/>
                      </div>
                      <div className={styles.listItemTitle}>{x.name}</div>
                    </div>
                  </Link>
                </div>
              )}
              {beds.length === 0 &&
                <div>Du hast noch keine Beete erstellt. Füge ein neues Beet hinzu, um Empfehlungen zu erhalten</div>}
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={visibleCreateModal}
        onClose={() => setVisibleCreateModal(false)}
      >
        <div>
          <div className={styles.modalTop}>
            <h1 className={cn("h4", styles.modalTop)}>Beet erstellen</h1>
          </div>
          <div>
            <TextInput
              name="name"
              type="text"
              placeholder="Name"
              innerRef={bedNameRef}
            />
          </div>
          <div className={styles.modalFooter}>
            <button
              className={cn("button-small", styles.modalButton)}
              onClick={() => {
                let name = bedNameRef.current.value;
                if (!name || name.trim().length === 0) {
                  return;
                }
                createBed(name);
              }}
            >
              Erstellen
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BedSearch;
