import React from "react";
import styles from "./FullScreenLoader.module.sass";
import {PuffLoader} from "react-spinners";

const FullScreenLoader = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.loader}>
        <PuffLoader size={75} color={"#B1B5C3"} loading={true}/>
      </div>
    </div>
  );
};

export default FullScreenLoader;
