import React, {useEffect, useRef, useState} from "react";
import cn from "classnames";
import styles from "./PlantModify.module.sass";
import Dropdown from "../../components/Dropdown";
import Icon from "../../components/Icon";
import TextInput from "../../components/TextInput";
import Switch from "../../components/Switch";
import {mode} from "../../components/Mode";
import TextArea from "../../components/TextArea";
import ValidationUtil from "../../components/ValidationUtil";
import {useHistory} from "react-router-dom";
import Modal from "../../components/Modal";
import FullScreenLoader from "../../components/FullScreenLoader";
import axios from "axios";

const PlantModify = (props) => {

  const defaultDropdown = {id: null, name: '---'};

  function mapToId(dataList, currenIdentifier) {
    return dataList.map(x => {
      Object.defineProperty(x, 'id', Object.getOwnPropertyDescriptor(x, currenIdentifier));
      delete x[currenIdentifier];
      return x;
    });
  }

  function findBy(dataList, key, value) {
    if (!value) {
      return null;
    }

    for (const x of dataList) {
      if (x[key] === value) {
        return x;
      }
    }
    return null;
  }

  function nullOrDefault(value) {
    if (!value) {
      return defaultDropdown;
    }
    return value;
  }

  let familyOptions = [
    defaultDropdown,
    {id: 'UMBELLIFER', name: 'Doldenblütler'},
    {id: 'GOOSEFOOT', name: 'Gänsefußgewächse'},
    {id: 'LEGUME', name: 'Hülsenfrüchtler'},
    {id: 'KNOTWEED', name: 'Knöterichgewächse'},
    {id: 'COMPOSITAE', name: 'Korbblütler'},
    {id: 'CRUCIFEROUS', name: 'Kreuzblütler'},
    {id: 'CUCURBITS', name: 'Kürbisgewächse'},
    {id: 'LILIES', name: 'Liliengewächse'},
    {id: 'LABIATES', name: 'Lippenblütler'},
    {id: 'SOLANACEAE', name: 'Nachtschattengewächse'}
  ];

  let nutritionalNeedsOptions = [
    defaultDropdown,
    {id: 'LOW', name: 'Schwachzehrer'},
    {id: 'MID', name: 'Mittelzehrer'},
    {id: 'HIGH', name: 'Starkzehrer'}
  ];

  let locationOptions = [
    defaultDropdown,
    {id: 'SUNNY', name: 'sonnig'},
    {id: 'SUNNY_TO_PARTIAL_SHADE', name: 'sonnig bis halbschattig'},
    {id: 'PARTIAL_SHADE', name: 'halbschattig'},
    {id: 'PARTIAL_SHADE_TO_SHADE', name: 'halbschattig bis schattig'},
    {id: 'SHADE', name: 'schattig'}
  ];

  let monthOptions = [
    defaultDropdown,
    {id: 'JANUARY', name: 'Januar'},
    {id: 'FEBRUARY', name: 'Februar'},
    {id: 'MARCH', name: 'März'},
    {id: 'APRIL', name: 'April'},
    {id: 'MAY', name: 'Mai'},
    {id: 'JUNE', name: 'Juni'},
    {id: 'JULY', name: 'Juli'},
    {id: 'AUGUST', name: 'August'},
    {id: 'SEPTEMBER', name: 'September'},
    {id: 'OCTOBER', name: 'Oktober'},
    {id: 'NOVEMBER', name: 'November'},
    {id: 'DECEMBER', name: 'Dezember'}
  ];

  let history = useHistory();

  let [loading, setLoading] = useState(true);
  let loadingTasks = useRef(0);
  let [errors, setErrors] = useState([]);
  let [visibleErrorModal, setVisibleErrorModal] = useState(false);

  let uuid = props.match.params.id;
  let currentMode = uuid ? mode.EDIT : mode.CREATE;

  let [plant, setPlant] = useState({});

  let [plantCollectionOptions, setPlantCollectionOptions] = useState();
  let [plantCollection, setPlantCollection] = useState(null);
  let [family, setFamily] = useState(null);
  let [nutritionalNeeds, setNutritionalNeeds] = useState(null);
  let [location, setLocation] = useState(null);
  let [earlySeedStartTime, setEarlySeedStartTime] = useState(null);
  let [earlySeedEndTime, setEarlySeedEndTime] = useState(null);
  let [normalSeedStartTime, setNormalSeedStartTime] = useState(null);
  let [normalSeedEndTime, setNormalSeedEndTime] = useState(null);
  let [harvestStartTime, setHarvestStartTime] = useState(null);
  let [harvestEndTime, setHarvestEndTime] = useState(null);
  let [winterSuitable, setWinterSuitable] = useState(false);

  const loadingTaskComplete = () => {
    loadingTasks.current++;
    if ((currentMode === mode.CREATE && loadingTasks.current === 1)
      || (currentMode === mode.EDIT && loadingTasks.current === 2)) {
      setLoading(false);
    }
  };

  useEffect(() => {
    axios.get(`/plants?collectionOnly=true`)
      .then(function (response) {
        let options = mapToId(response.data, "uuid");
        options.unshift(defaultDropdown);
        setPlantCollectionOptions(() => {
          return options;
        });
        loadingTaskComplete();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plant]);

  useEffect(() => {
    if (currentMode === mode.CREATE) {
      return;
    }
    axios.get(`/plant/${uuid}`)
      .then(function (response) {
        setPlant(() => response.data);
        loadingTaskComplete();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  useEffect(() => {
    setPlantCollection(() => nullOrDefault(findBy(plantCollectionOptions, "id", plant.plantCollection ? plant.plantCollection.uuid : null)));
    setFamily(() => nullOrDefault(findBy(familyOptions, "name", plant.family)));
    setNutritionalNeeds(() => nullOrDefault(findBy(nutritionalNeedsOptions, "name", plant.nutritionalNeeds)));
    setLocation(() => nullOrDefault(findBy(locationOptions, "name", plant.location)));
    setEarlySeedStartTime(() => nullOrDefault(findBy(monthOptions, "name", plant.earlySeedStartTime)));
    setEarlySeedEndTime(() => nullOrDefault(findBy(monthOptions, "name", plant.earlySeedEndTime)));
    setNormalSeedStartTime(() => nullOrDefault(findBy(monthOptions, "name", plant.normalSeedStartTime)));
    setNormalSeedEndTime(() => nullOrDefault(findBy(monthOptions, "name", plant.normalSeedEndTime)));
    setHarvestStartTime(() => nullOrDefault(findBy(monthOptions, "name", plant.harvestStartTime)));
    setHarvestEndTime(() => nullOrDefault(findBy(monthOptions, "name", plant.harvestEndTime)));
    setWinterSuitable(() => !!plant.winterSuitable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plant, plantCollectionOptions]);

  useEffect(() => {
    setVisibleErrorModal(errors.length !== 0);
  }, [errors]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let images = e.target.image.files;
    let createPlantDto = createDto(e.target);
    let validate = ValidationUtil.validate(getValidationRules(), createPlantDto);
    setErrors(validate);

    if (validate.length === 0) {
      let config = {method: 'POST', url: "/plants"};
      if (currentMode === mode.EDIT) {
        config.method = 'PUT';
        config.url = `/plant/${uuid}`;
      }
      axios.request({...config, data: createPlantDto})
        .then(function (response) {
          if (images.length !== 0) {
            let imagePayload = new FormData();
            imagePayload.append("file", images[0])
            axios.post(`/plant/${response.data.uuid}/image`, imagePayload)
              .then(() => history.push(`/plant/${response.data.uuid}`));
          } else {
            history.push(`/plant/${response.data.uuid}`);
          }
        })
        .catch(function (error) {
          setErrors(["Es ist ein unbekannter Fehler aufgetreten. Bitte erneut versuchen!"]);
          console.log(error);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const createDto = (target) => {
    return {
      name: nullOrValue(target.name.value),
      otherNames: nullOrValue(target.otherNames.value),
      collection: false,
      plantCollection: nullOrValue(plantCollection) ? {uuid: plantCollection.id} : null,
      winterSuitable: nullOrValue(winterSuitable),
      family: nullOrValue(family),
      nutritionalNeeds: nullOrValue(nutritionalNeeds),
      earlySeedStartTime: nullOrValue(earlySeedStartTime),
      earlySeedEndTime: nullOrValue(earlySeedEndTime),
      normalSeedStartTime: nullOrValue(normalSeedStartTime),
      normalSeedEndTime: nullOrValue(normalSeedEndTime),
      harvestStartTime: nullOrValue(harvestStartTime),
      harvestEndTime: nullOrValue(harvestEndTime),
      periodOfTimeLower: nullOrValue(target.periodOfTimeLower.value),
      periodOfTimeUpper: nullOrValue(target.periodOfTimeUpper.value),
      germinationTimeLower: nullOrValue(target.germinationTimeLower.value),
      germinationTimeUpper: nullOrValue(target.germinationTimeUpper.value),
      temperatureLower: nullOrValue(target.temperatureLower.value),
      temperatureUpper: nullOrValue(target.temperatureUpper.value),
      seedDepthLower: nullOrValue(target.seedDepthLower.value),
      seedDepthUpper: nullOrValue(target.seedDepthUpper.value),
      plantSpacingLower: nullOrValue(target.plantSpacingLower.value),
      plantSpacingUpper: nullOrValue(target.plantSpacingUpper.value),
      location: nullOrValue(location),
      description: nullOrValue(target.description.value)
    };
  };

  const nullOrValue = (value) => {
    if (value) {
      return typeof value === 'object' && 'id' in value ? value['id'] : value;
    }
    return null;
  }

  const getValidationRules = () => {
    return {
      name: {displayName: "Name", validations: [{type: ValidationUtil.NOT_BLANK}]}
      //todo add validation for values upper and lower limits
    };
  };

  const deletePlant = () => {
    setLoading(true);
    axios.delete(`/plant/${uuid}`)
      .then(function () {
        history.push("/plants");
      }).catch(function (error) {
      setErrors(() => ["Es ist ein unbekannter Fehler aufgetreten. Bitte erneut versuchen!"]);
      console.log(error);
      setLoading(() => false);
    });
  };

  if (loading) {
    return <FullScreenLoader/>;
  }

  return (
    <>
      <div className={cn("section", styles.section)}>
        <div className={cn("container", styles.container)}>
          <div className={styles.wrapper}>
            <div className={styles.head}>
              <div className={cn("h2", styles.title)}>
                {currentMode === mode.EDIT ? 'Pflanze bearbeiten' : 'Pflanze erstellen'}
              </div>
            </div>
            <Modal
              visible={visibleErrorModal}
              onClose={() => setVisibleErrorModal(false)}
            >
              <div>{errors}</div>
            </Modal>
            <form className={styles.form} method="POST" onSubmit={handleSubmit}>
              <div className={styles.list}>
                <div className={styles.item}>
                  <div className={styles.category}>Bild</div>
                  <div className={styles.note}>
                    Wähle eine Datei oder ziehe sie in das Upload Feld
                  </div>
                  <div className={styles.file}>
                    <input className={styles.load} type="file" name="image"/>
                    <div className={styles.icon}>
                      <Icon name="upload-file" size="24"/>
                    </div>
                    <div className={styles.format}>
                      PNG, JPG. Max 10mb.
                    </div>
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.category}>Pflanzen Details</div>
                  <div className={styles.fieldset}>
                    <TextInput
                      className={styles.field}
                      label="Name"
                      name="name"
                      type="text"
                      defaultValue={plant.name}
                      required
                    />
                    <TextInput
                      className={styles.field}
                      label="Weitere Namen (Komma separiert)"
                      name="otherNames"
                      type="text"
                      defaultValue={plant.otherNames}
                    />
                    <div className={styles.row}>
                      <div className={styles.col}>
                        <div className={styles.field}>
                          <div className={styles.label}>Pflanzengruppe</div>
                          <Dropdown
                            className={styles.dropdown}
                            value={plantCollection}
                            setValue={setPlantCollection}
                            options={plantCollectionOptions}
                          />
                        </div>
                      </div>
                      <div className={styles.col}>
                        <div className={styles.field}>
                          <div className={styles.label}>Familie</div>
                          <Dropdown
                            className={styles.dropdown}
                            value={family}
                            setValue={setFamily}
                            options={familyOptions}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.row}>
                      <div className={styles.col}>
                        <div className={styles.field}>
                          <div className={styles.label}>Nährstoffbedarf</div>
                          <Dropdown
                            className={styles.dropdown}
                            value={nutritionalNeeds}
                            setValue={setNutritionalNeeds}
                            options={nutritionalNeedsOptions}
                          />
                        </div>
                      </div>
                      <div className={styles.col}>
                        <div className={styles.field}>
                          <div className={styles.label}>Standort</div>
                          <Dropdown
                            className={styles.dropdown}
                            value={location}
                            setValue={setLocation}
                            options={locationOptions}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.row}>
                      <div className={styles.col}>
                        <div className={styles.field}>
                          <div className={styles.label}>Vorsaat Beginn</div>
                          <Dropdown
                            className={styles.dropdown}
                            value={earlySeedStartTime}
                            setValue={setEarlySeedStartTime}
                            options={monthOptions}
                          />
                        </div>
                      </div>
                      <div className={styles.col}>
                        <div className={styles.field}>
                          <div className={styles.label}>Vorsaat Ende</div>
                          <Dropdown
                            className={styles.dropdown}
                            value={earlySeedEndTime}
                            setValue={setEarlySeedEndTime}
                            options={monthOptions}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.row}>
                      <div className={styles.col}>
                        <div className={styles.field}>
                          <div className={styles.label}>Direktsaat Beginn</div>
                          <Dropdown
                            className={styles.dropdown}
                            value={normalSeedStartTime}
                            setValue={setNormalSeedStartTime}
                            options={monthOptions}
                          />
                        </div>
                      </div>
                      <div className={styles.col}>
                        <div className={styles.field}>
                          <div className={styles.label}>Direktsaat Ende</div>
                          <Dropdown
                            className={styles.dropdown}
                            value={normalSeedEndTime}
                            setValue={setNormalSeedEndTime}
                            options={monthOptions}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.row}>
                      <div className={styles.col}>
                        <div className={styles.field}>
                          <div className={styles.label}>Ernte Beginn</div>
                          <Dropdown
                            className={styles.dropdown}
                            value={harvestStartTime}
                            setValue={setHarvestStartTime}
                            options={monthOptions}
                          />
                        </div>
                      </div>
                      <div className={styles.col}>
                        <div className={styles.field}>
                          <div className={styles.label}>Ernte Ende</div>
                          <Dropdown
                            className={styles.dropdown}
                            value={harvestEndTime}
                            setValue={setHarvestEndTime}
                            options={monthOptions}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.row}>
                      <div className={styles.col}>
                        <TextInput
                          className={styles.field}
                          label="Aussaat bis Ernte (in Wochen) von"
                          name="periodOfTimeLower"
                          type="text"
                          defaultValue={plant.periodOfTimeLower}
                        />
                      </div>
                      <div className={styles.col}>
                        <TextInput
                          className={styles.field}
                          label="Aussaat bis Ernte (in Wochen) bis"
                          name="periodOfTimeUpper"
                          type="text"
                          defaultValue={plant.periodOfTimeUpper}
                        />
                      </div>
                    </div>
                    <div className={styles.row}>
                      <div className={styles.col}>
                        <TextInput
                          className={styles.field}
                          label="Keimdauer (in Tagen) von"
                          name="germinationTimeLower"
                          type="text"
                          defaultValue={plant.germinationTimeLower}
                        />
                      </div>
                      <div className={styles.col}>
                        <TextInput
                          className={styles.field}
                          label="Keimdauer (in Tagen) bis"
                          name="germinationTimeUpper"
                          type="text"
                          defaultValue={plant.germinationTimeUpper}
                        />
                      </div>
                    </div>
                    <div className={styles.row}>
                      <div className={styles.col}>
                        <TextInput
                          className={styles.field}
                          label="Keimtemperatur (in °C) von"
                          name="temperatureLower"
                          type="text"
                          defaultValue={plant.temperatureLower}
                        />
                      </div>
                      <div className={styles.col}>
                        <TextInput
                          className={styles.field}
                          label="Keimtemperatur (in °C) bis"
                          name="temperatureUpper"
                          type="text"
                          defaultValue={plant.temperatureUpper}
                        />
                      </div>
                    </div>
                    <div className={styles.row}>
                      <div className={styles.col}>
                        <TextInput
                          className={styles.field}
                          label="Saattiefe (in cm) von"
                          name="seedDepthLower"
                          type="text"
                          defaultValue={plant.seedDepthLower}
                        />
                      </div>
                      <div className={styles.col}>
                        <TextInput
                          className={styles.field}
                          label="Saattiefe (in cm) bis"
                          name="seedDepthUpper"
                          type="text"
                          defaultValue={plant.seedDepthUpper}
                        />
                      </div>
                    </div>
                    <div className={styles.row}>
                      <div className={styles.col}>
                        <TextInput
                          className={styles.field}
                          label="Pflanzenabstand (in cm) von"
                          name="plantSpacingLower"
                          type="text"
                          defaultValue={plant.plantSpacingLower}
                        />
                      </div>
                      <div className={styles.col}>
                        <TextInput
                          className={styles.field}
                          label="Pflanzenabstand (in cm) bis"
                          name="plantSpacingUpper"
                          type="text"
                          defaultValue={plant.plantSpacingUpper}
                        />
                      </div>
                    </div>
                    <TextArea
                      className={styles.field}
                      label="Besonderheiten"
                      name="description"
                      type="text"
                      defaultValue={plant.description}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.options}>
                <div className={styles.option}>
                  <div className={styles.box}>
                    <div className={styles.category}>Winterhart</div>
                    <div className={styles.text}>
                      Ist die Pflanzenart winterhart?
                    </div>
                  </div>
                  <Switch value={winterSuitable} setValue={setWinterSuitable}/>
                </div>
              </div>
              <div className={styles.foot}>
                <button
                  className={cn("button", styles.button)}
                  type="submit"
                >
                  <span>Speichern</span>
                </button>
                {currentMode === mode.EDIT && <button
                  className={cn("button-pink", styles.delete)}
                  type="button"
                  onClick={() => {
                    deletePlant()
                  }}
                >
                  <span>Löschen</span>
                </button>
                }
              </div>
            </form>
            {/*  todo make also possible to adjust the neighbours here*/}
          </div>
        </div>
      </div>
    </>
  );
};

export default PlantModify;
