import React, {useState} from "react";
import {Link} from "react-router-dom";
import cn from "classnames";
import styles from "./Header.module.sass";

const nav = [
  {
    url: "/plants",
    title: "Pflanzen",
  },
  {
    url: "/calendar",
    title: "Kalender",
  },
  {
    url: "/beds",
    title: "Beete",
  }
];

const Headers = () => {
  const [visibleNav, setVisibleNav] = useState(false);

  return (
    <header className={styles.header}>
      <div className={cn("container", styles.container)}>
        <Link className={styles.logo} to="/">
          <img
            className={styles.pic}
            src={'/logo.webp'}
            alt={'Gartenatlas'}
          />
        </Link>
        <div className={cn(styles.wrapper, {[styles.active]: visibleNav})}>
          <nav className={styles.nav}>
            {nav.map((x, index) => (
              <Link
                className={styles.link}
                // activeClassName={styles.active}
                to={x.url}
                key={index}
              >
                {x.title}
              </Link>
            ))}
          </nav>
        {/*todo*/}
          {false && <Link
            className={cn("button-small", styles.button)}
            to="/create-variants"
          >
            Erstellen
          </Link>}
        </div>
        {/*todo*/}
        {false && <Link
          className={cn("button-small", styles.button)}
          to="/create-variants"
        >
          Erstellen
        </Link>}
        <button
          className={cn(styles.burger, {[styles.active]: visibleNav})}
          onClick={() => setVisibleNav(!visibleNav)}
        ></button>
      </div>
    </header>
  );
};

export default Headers;
