import React from "react";
import cn from "classnames";
import styles from "./ItemPreview.module.sass";
import {Link} from "react-router-dom";

const ItemPreview = ({className, item, subtitle, description, link = true}) => {
  return (
    <div className={cn(styles.item, className)}>
      <input name={"uuid"} type={"hidden"} value={item.uuid}/>
      <Link to={link ? '/plant/' + item.uuid : '#'}>
        <div className={styles.thumbnail}>
          <img src={item.imagePath + "?gaat=" + localStorage.getItem("gaat")} alt="Bild"/>
        </div>
      </Link>
      <div className={styles.details}>
        <Link to={link ? '/plant/' + item.uuid : '#'}>
          <div className={styles.title}>{subtitle}</div>
          <div className={styles.value}>{item.name}</div>
        </Link>
      </div>
      {description && <div className={styles.description}>
        <div>{description}</div>
      </div>}
    </div>
  );
};

export default ItemPreview;
