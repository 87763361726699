import React, {useEffect, useState} from "react";
import cn from "classnames";
import styles from "./Calendar.module.sass";
import DropdownEmpty from "../../components/DropdownEmpty";
import {useHistory} from "react-router-dom";
import Item from "../../components/Item";
import TextArea from "../../components/TextArea";
import FullScreenLoader from "../../components/FullScreenLoader";
import axios from "axios";

const Calendar = (props) => {

  let history = useHistory();
  let [loading, setLoading] = useState(true);

  let [monthWorkload, setMonthWorkload] = useState();
  let [edit, setEdit] = useState(false);

  let monthOptions = [
    {id: '0', name: 'Januar'},
    {id: '1', name: 'Februar'},
    {id: '2', name: 'März'},
    {id: '3', name: 'April'},
    {id: '4', name: 'Mai'},
    {id: '5', name: 'Juni'},
    {id: '6', name: 'Juli'},
    {id: '7', name: 'August'},
    {id: '8', name: 'September'},
    {id: '9', name: 'Oktober'},
    {id: '10', name: 'November'},
    {id: '11', name: 'Dezember'}
  ];

  let [month, setMonth] = useState(monthOptions[props.match.params.id ? props.match.params.id : new Date().getMonth()]);

  if (props.match.params.id !== month.id) {
    history.push(`/calendar/${month.id}`);
  }

  useEffect(() => {
    axios.get(`/monthWorkload/${month.id}`)
      .then(function (response) {
        setMonthWorkload(() => response.data);
        setLoading(() => false);
      });
  }, [month]);

  const updateMonthWorkload = () => {
    setLoading(true);
    axios.put(
      `/monthWorkload/${month.id}`,
      {description: document.getElementById("description").value})
      .then(function (response) {
        setEdit(() => false);
        setMonthWorkload(() => response.data);
        setLoading(() => false);
      });
  };

  if (loading) {
    return <FullScreenLoader/>;
  }

  return (
    <>
      <div className={cn("section", styles.section)}>
        <div className={cn("container", styles.container)}>
          <div className={styles.bg}>
            <div className={styles.top}>
              <div className={styles.box}>
                <DropdownEmpty
                  className={styles.dropdown}
                  value={month}
                  setValue={setMonth}
                  options={monthOptions}
                />
              </div>
            </div>
            <div className={styles.area}>
              <div className={styles.stage}>Was gibt es zu tun?</div>
              {!edit &&
                <div className={cn(styles.description, styles.allowLineBreaks)}>{monthWorkload.description}</div>}
              {edit && <TextArea
                id={'description'}
                className={styles.description}
                defaultValue={monthWorkload.description}
              />}
              <div className={styles.buttonWrapper}>
                {!edit &&
                  <button type={"button"} className={"button-stroke button-small"} onClick={() => setEdit(() => true)}>
                    <span>Aufgaben bearbeiten</span>
                  </button>}
                {edit && <button type={"button"} className={"button-stroke button-small"}
                                 onClick={() => updateMonthWorkload()}>
                  <span>Speichern</span>
                </button>}
              </div>
            </div>
            {monthWorkload.earlySeedPlants && <div className={styles.area}>
              <div className={styles.stage}>Vorsaat</div>
              <div className={styles.list}>
                {monthWorkload.earlySeedPlants.map((x, index) => (
                  <div className={styles.listItem} key={index}>
                    <Item item={x}/>
                  </div>
                ))}
              </div>
              <div className={styles.areaDivider}/>
            </div>}
            {monthWorkload.normalSeedPlants && <div className={styles.area}>
              <div className={styles.stage}>Direktsaat</div>
              <div className={styles.list}>
                {monthWorkload.normalSeedPlants.map((x, index) => (
                  <div className={styles.listItem} key={index}>
                    <Item item={x}/>
                  </div>
                ))}
              </div>
              <div className={styles.areaDivider}/>
            </div>}
            {monthWorkload.harvestPlants && <div className={styles.area}>
              <div className={styles.stage}>Erntezeit</div>
              <div className={styles.list}>
                {monthWorkload.harvestPlants.map((x, index) => (
                  <div className={styles.listItem} key={index}>
                    <Item item={x}/>
                  </div>
                ))}
              </div>
            </div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default Calendar;
