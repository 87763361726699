import React, {useEffect, useState} from "react";
import cn from "classnames";
import styles from "./PlantDetail.module.sass";
import Neighbours from "./Neighbours";
import Properties from "./Properties";
import FullScreenLoader from "../../components/FullScreenLoader";
import axios from "axios";

const PlantDetail = (props) => {

  const [activeIndex, setActiveIndex] = useState(0);

  let [loading, setLoading] = useState(true);
  let [plant, setPlant] = useState({});
  let uuid = props.match.params.id;

  useEffect(() => {
    setLoading(() => true);
    axios.get(`/plant/${uuid}?allAttributes=true`)
      .then(function (response) {
        setPlant(() => response.data);
        setLoading(() => false);
      });
  }, [uuid]);

  if (loading) {
    return <FullScreenLoader/>;
  }

  return (
    <>
      <div className={cn("section", styles.section)}>
        <div className={cn("container", styles.container)}>
          <div className={styles.bg}>
            <div className={styles.preview}>
              <div className={styles.categories}>
                {plant.collection &&
                  <div className={cn("status-purple", styles.category)}
                  >
                    Pflanzengruppe
                  </div>}
                {plant.winterSuitable &&
                  <div className={cn("status-blue", styles.category)}
                  >
                    Winterhart
                  </div>}
              </div>
              <img
                src={plant.imagePath + "?gaat=" + localStorage.getItem("gaat")}
                alt="Detailbild"
              />
            </div>
            <Properties className={styles.propertiesMobile} plant={plant}/>
            <div className={styles.subtitle}>
              <h1 className={"h5"}>Mischkultur</h1>
              <div>Unter Beachtung der guten und schlechten Nachbarn können gleichzeitig unterschiedliche Nutzplfanzen
                auf einer Fläche bewirtet werden.
                Gute Nachbarn sollten bei der Auswahl für ein Beet bevozugt werden. Auf schlechte Nachbarn sollte
                verzichtet werden.
              </div>
            </div>
            <div className={styles.nav}>
              {["Gute Nachbarn", "Schlechte Nachbarn"].map((x, index) => (
                <button
                  className={cn(
                    {[styles.active]: index === activeIndex},
                    styles.link
                  )}
                  onClick={() => setActiveIndex(index)}
                  key={index}
                >
                  {x}
                </button>
              ))}
            </div>
            {activeIndex === 0 && (plant.goodNeighbours ?
              <Neighbours className={styles.neighbours} plant={plant} neighbours={plant.goodNeighbours}
                          searchText={"Welchen guten Nachbarn suchst du?"}/> : <div>Keine guten Nachbarn bekannt</div>)}
            {activeIndex === 1 && (plant.badNeighbours ?
              <Neighbours className={styles.neighbours} plant={plant} neighbours={plant.badNeighbours}
                          searchText={"Welchen schlechten Nachbarn suchst du?"}/> :
              <div>Keine schlechten Nachbarn bekannt</div>)}
            {/*<Options className={styles.options} />*/}
            {/* todo add to bed with <Options> */}
          </div>
          <Properties className={styles.propertiesNonMobile} plant={plant}/>
        </div>
      </div>
    </>
  );
};

export default PlantDetail;
