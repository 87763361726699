import React from "react";
import cn from "classnames";
import styles from "./Attribute.module.sass";

const Attribute = ({className, meta, value}) => {
  return (
    <div className={cn(styles.attribute, className)}>
      <div className={styles.thumbnail}>
        <img src={meta.imagePath} alt="Attribut"/>
      </div>
      <div className={styles.details}>
        <div className={styles.title}>{meta.title}</div>
        <div className={styles.value}>{value}</div>
      </div>
    </div>
  );
};

export default Attribute;
