import {Route, Switch, useLocation} from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import VariantsCreate from "./screens/VariantsCreate";
import PlantModify from "./screens/PlantModify";
import BedDetail from "./screens/BedDetail";
import PlantSearch from "./screens/PlantSearch";
import PlantDetail from "./screens/PlantDetail";
import React from "react";
import Calendar from "./screens/Calendar";
import {Redirect, useHistory} from "react-router";
import Login from "./screens/Login";
import Error from "./components/Error";
import axios from "axios";
import BedSearch from "./screens/BedSearch";

export const BASE_URL = 'https://api-garden.stefanschomacker.de';

function App() {
  let history = useHistory();

  //AXIOS
  axios.defaults.baseURL = BASE_URL + '/api/v1';
  axios.defaults.timeout = 0;
  axios.defaults.headers.post['Content-Type'] = 'application/json';

  axios.interceptors.request.use(request => {
    let token = localStorage.getItem("gaat");
    request.headers['Authorization'] = "Bearer " + token;
    return request;
  }, error => {
    console.log(error);
    return Promise.reject(error);
  });

  axios.interceptors.response.use(response => {
    return response;
  }, error => {
    console.log(error);
    history.replace(history.location.pathname, {
      errorStatusCode: (error.response && error.response.status) ? error.response.status : 500
    });
    return Promise.reject(error);
  });

  let location = useLocation();
  if (!localStorage.getItem("gaat") && location.pathname !== '/login') {
    return <Redirect to="/login"/>;
  }
  return (
    <Switch>
      <Route path="/login" render={() => {
        let accessToken = localStorage.getItem("gaat");
        if (accessToken) {
          return <Redirect to="/"/>;
        }
        return <Login/>
      }}/>
      <Route exact path="/" render={() => {
        return <Redirect to="/plants"/>;
      }}/>
      <Route
        exact
        path="/plants"
        render={() => (
          <Page>
            <PlantSearch/>
          </Page>
        )}
      />
      <Route
        exact
        path="/plant/create"
        render={(props) => (
          <Page>
            <PlantModify {...props}/>
          </Page>
        )}
      />
      <Route
        exact
        path="/plant/:id([a-zA-Z0-9\-]+)"
        render={(props) => (
          <Page>
            <PlantDetail {...props}/>
          </Page>
        )}
      />
      <Route
        exact
        path="/create-variants"
        render={() => (
          <Page>
            <VariantsCreate/>
          </Page>
        )}
      />
      <Route
        exact
        path="/plant/:id([a-zA-Z0-9\-]+)/edit"
        render={(props) => (
          <Page>
            <PlantModify {...props}/>
          </Page>
        )}
      />
      <Route
        exact
        path="/calendar/:id([0-9]+)?"
        render={(props) => (
          <Page>
            <Calendar {...props}/>
          </Page>
        )}
      />
      <Route
        exact
        path="/beds"
        render={() => (
          <Page>
            <BedSearch/>
          </Page>
        )}
      />
      <Route
        exact
        path="/bed/:id([a-zA-Z0-9\-]+)"
        render={(props) => (
          <Page>
            <BedDetail {...props}/>
          </Page>
        )}
      />
      <Route
        render={() => (
          <Page>
            <Error description={"404 Oh. Die Seite konnte nicht gefunden werden."}/>
          </Page>
        )}
      />
    </Switch>
  );
}

export default App;
