import React from "react";
import styles from "./Footer.module.sass";
import packageJson from "../../../package.json";

const Footers = () => {
  return (
    <footer className={styles.footer}>
      <div className={"container"}>
        <div className={styles.foot}>
          <div className={styles.info}>
            GartenAtlas
          </div>
          <div className={styles.info}>
            {packageJson.version}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footers;
