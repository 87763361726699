import React from "react";
import {Link} from "react-router-dom";
import cn from "classnames";
import styles from "./VariantsCreate.module.sass";

const items = [
  {
    url: "/plant/create",
    buttonText: "Pflanze erstellen",
    image: "/icon/gardening.png",
  },
  {
    url: "/collection/create",
    buttonText: "Gruppe erstellen",
    image: "/icon/vegetables.png",
  },
  {
    url: "/neighbour/create",
    buttonText: "Nachbar erstellen",
    image: "/icon/raised_bed.png",
  },
];

const VariantsCreate = () => {
  return (
    <div className={styles.page}>
      <div className={cn("section-pt80", styles.section)}>
        <div className={cn("container", styles.container)}>
          <div className={styles.top}>
            <h1 className={cn("h2", styles.title)}>Erstellen</h1>
            <div className={styles.info}>
              Nutze <span>"Pflanze"</span>, um eine neue Pflanze zu erstellen. Die Kategorie <span>"Gruppe"</span> zum
              Erstellen einer Pflanzengruppe und <span>"Nachbar"</span>, um die Verbindungen zwischen den Pflanzen
              herzustellen.
            </div>
          </div>
          <div className={styles.list}>
            {items.map((x, index) => (
              <div className={styles.item} key={index}>
                <div className={styles.preview}>
                  <img src={x.image} alt="Erstellen"/>
                </div>
                <Link className={cn("button-stroke", styles.button)} to={x.url}>
                  {x.buttonText}
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VariantsCreate;
