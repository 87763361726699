import React from "react";
import {useLocation} from "react-router-dom";
import Error from "./components/Error";

const ErrorHandler = ({children}) => {
  const location = useLocation();

  let code = -1;
  if (location.state && location.state["errorStatusCode"]) {
    code = location.state["errorStatusCode"]
  }

  let description = `${code} Oh. Ein unbekannter Fehler ist aufgetreten.`;
  if (code === 404) {
    description = `${code} Oh. Die Seite konnte nicht gefunden werden.`
  }

  if (code !== -1) {
    window.history.replaceState({}, document.title)
    return <Error description={description}/>;
  }
  return children;
};

export default ErrorHandler;
