import React, {useState} from "react";
import styles from "./Login.module.sass";
import FullScreenLoader from "../../components/FullScreenLoader";
import TextInput from "../../components/TextInput";
import {useHistory} from "react-router-dom";
import axios from "axios";
import Modal from "../../components/Modal";
import {BASE_URL} from "../../App";

const Login = () => {
  let history = useHistory();
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(false);
  let [visibleErrorModal, setVisibleErrorModal] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let username = e.target.username.value;
    let password = e.target.password.value;

    axios.post(BASE_URL + '/login', {username: username, password: password})
      .then(function (response) {
        let token = response.data.access_token;

        if (token) {
          localStorage.clear();
          localStorage.setItem("gaat", token);
        }

        history.push("/plants");
      }).catch(function (error) {
      console.log(error);
      setLoading(() => false);
      setErrors(() => ["Der Login war nicht erfolgreich. Bitte erneut versuchen!"]);
      setVisibleErrorModal(() => true);
    });
  };

  if (loading) {
    return <FullScreenLoader/>;
  }

  return (
    <div className={styles.container}>
      <Modal
        visible={visibleErrorModal}
        onClose={() => setVisibleErrorModal(false)}
      >
        <div>{errors}</div>
      </Modal>
      <div>
        <form onSubmit={handleSubmit}>
          <TextInput
            className={styles.field}
            label="Username"
            name="username"
            type="text"
          />
          <TextInput
            className={styles.field}
            label="Passwort"
            name="password"
            type="password"
          />
          <button
            className={"button"}
            type="submit"
          >
            <span>Login</span>
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
