import React, {useEffect, useRef, useState} from "react";
import cn from "classnames";
import styles from "./PlantSearch.module.sass";
import Icon from "../../components/Icon";
import Item from "../../components/Item";
import Fuse from "fuse.js";
import FullScreenLoader from "../../components/FullScreenLoader";
import axios from "axios";

const PlantSearch = () => {
  let [loading, setLoading] = useState(true);

  let [allPlants, setAllPlants] = useState([]);
  let [searchPlants, setSearchPlants] = useState([]);
  let search = useRef("");

  let timer = useRef(null);
  let fuse = useRef(null);

  useEffect(() => {
    axios.get('/plants')
      .then(function (response) {
        setAllPlants(() => response.data);
        setLoading(() => false);
      }).catch(function (error) {
      console.log(error);
    });
  }, []);

  useEffect(() => {
    setSearchPlants(() => allPlants);
    fuse.current = new Fuse(allPlants, {
      shouldSort: true,
      threshold: 0.2,
      findAllMatches: true,
      keys: [
        "name",
        "otherNames"
      ]
    });
  }, [allPlants]);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      searchPlant();
    }
  }

  const handleChange = (e) => {
    clearTimeout(timer.current);
    search.current = e.target.value;
    timer.current = setTimeout(searchPlant, 600);
  }

  const searchPlant = () => {
    if (search.current.trim().length === 0) {
      setSearchPlants(allPlants);
    } else {
      let searchedPlants = fuse.current.search(search.current).map(plant => plant.item);
      setSearchPlants(searchedPlants);
    }
  };


  if (loading) {
    return <FullScreenLoader/>;
  }

  return (
    <div className={cn("section-pt80", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.top}>
          <div className={styles.search}>
            <input
              className={styles.input}
              type="text"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              name="search"
              placeholder="Welche Pflanze suchst du?"
              required
            />
            <button className={styles.result}>
              <Icon name="search" size="16"/>
            </button>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.wrapper}>
            <div className={styles.list}>
              {searchPlants.map((x, index) => (
                <div className={styles.listItem} key={index}>
                  <Item item={x}/>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlantSearch;
