import React, {useEffect} from "react";
import {useLocation, withRouter} from "react-router-dom";
import {clearAllBodyScrollLocks} from "body-scroll-lock";
import styles from "./Page.module.sass";
import Header from "../Header";
import Footer from "../Footer";
import ErrorHandler from "../../ErrorHandler";

const Page = ({children}) => {
  const {pathname} = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllBodyScrollLocks();
  }, [pathname]);

  return (
    <div className={styles.page}>
      <Header/>
      <ErrorHandler>
        <div className={styles.inner}>{children}</div>
      </ErrorHandler>
      <Footer/>
    </div>
  );
};

export default withRouter(Page);
